<template>
  <div class="home-page">
    <div class="db-card">
      <div class="card-title">我的待办</div>
      <div class="card-content">
        <div class="db-item" @click="go('ys', item)" v-for="item in budgetYearUpcoming" :key="'ys' + item.id + '-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-yusuan"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            {{item.year + '年' + (item.type === 'type' ? '追加' : '') + '预算'}}
          </div>
          <div class="db-role">
            {{roleMap[item.roleId]}}
          </div>
        </div>
        <div class="db-item" @click="go('cg', item)" v-for="item in purchaseBatchUpcoming" :key="'cg' + item.id + '-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-caigou"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            {{item.year + '年采购(' + item.batch + ')'}}
          </div>
          <div class="db-role">
            {{roleMap[item.roleId]}}
          </div>
        </div>
        <div class="db-item" @click="go('cgfs', item)" v-for="item in purchaseMethodBatchUpcoming" :key="'cgfs' + item.id + '-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-caigoufangshi"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            {{item.year + '年采购方式(' + item.batch + ')'}}
          </div>
          <div class="db-role">
            {{roleMap[item.roleId]}}
          </div>
        </div>
        <div class="db-item" @click="go('yanshou', item)" v-for="item in acceptanceUpcoming" :key="'ys-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-cheliangleixing"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            资产验收
          </div>
          <div class="db-role">
            {{roleMap[item.roleId]}}
          </div>
        </div>
        <div class="db-item" @click="go('wx', item)" v-for="item in repairUpcoming" :key="'wx-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-install"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            维修申请
          </div>
          <div class="db-role">
            {{roleMap[item.roleId]}}
          </div>
        </div>
        <div class="db-item" @click="go('bf', item)" v-for="item in scrapUpcoming" :key="'bf-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-right-rotate"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            报废申请
          </div>
          <div class="db-role">
            {{roleMap[item.roleId]}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  budgetYearRequest,
  purchaseBatchRequest,
  purchaseMethodBatchRequest,
  acceptanceRequest,
  assetsRepairApplyRequest,
  assetsScrapApplyRequest
} from '@/api'

import {
  localstorageHelper
} from '@/fmlib'

export default {
  data () {
    return {
      budgetYearUpcoming: [],
      purchaseBatchUpcoming: [],
      purchaseMethodBatchUpcoming: [],
      acceptanceUpcoming: [],
      repairUpcoming: [],
      scrapUpcoming: []
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    roleMap () {
      let data = {}
      this.$store.getters.availableRoles.forEach(v => {
        data[String(v.id)] = v.name
      })
      return data
    }
  },
  methods: {
    async loadData () {
      let pList = []
      pList.push(budgetYearRequest.getUpcoming())
      pList.push(purchaseBatchRequest.getUpcoming())
      pList.push(purchaseMethodBatchRequest.getUpcoming())
      pList.push(acceptanceRequest.getUpcoming())
      pList.push(assetsRepairApplyRequest.getUpcoming())
      pList.push(assetsScrapApplyRequest.getUpcoming())
      this.budgetYearUpcoming = await pList[0]
      this.purchaseBatchUpcoming = await pList[1]
      this.purchaseMethodBatchUpcoming = await pList[2]
      this.acceptanceUpcoming = await pList[3]
      this.repairUpcoming = await pList[4]
      this.scrapUpcoming = await pList[5]
      Promise.all(pList)
    },
    go (type, item) {
      let currentRoleId = this.$store.getters.currentRole.id
      let rootParm = null
      if (type === 'ys') {
        rootParm = {
          name: 'budget.details', query: {
            id: item.id,
            type: 'handle'
          }
        }
      } else if (type === 'cg') {
        rootParm = {
          name: 'purchase.details', query: {
            id: item.id,
            type: 'handle'
          }
        }
      } else if (type === 'cgfs') {
        rootParm = {
          name: 'purchase_method.details', query: {
            id: item.id,
            type: 'handle'
          }
        }
      } else if (type === 'yanshou') {
        rootParm = {
          name: 'accept.my'
        }
      } else if (type === 'wx') {
        rootParm = {
          name: 'repair.apply'
        }
      } else if (type === 'bf') {
        rootParm = {
          name: 'scrap.apply'
        }
      }
      if (rootParm != null) {
        if (Number(item.roleId) === currentRoleId) {
          this.$router.push(rootParm)
        } else {
          let findRole = this.$store.getters.availableRoles.find(v => v.id === Number(item.roleId))
          if (findRole) {
            this.$store.dispatch('setOverviewRole', false)
            localstorageHelper.updateData('roleId', findRole.id)
            localstorageHelper.updateData('jumpPageParm', rootParm)
            window.location.reload()
          } else {
            this.$notice.info({ title: '当前角色异常'})
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.home-page {
  width: 100%;
  height: 100%;
}
.db-card {
  margin: 10px;
  background: #fff;
  padding: 20px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}
.card-title {
  font-size: 16px;
  color: #000;
  margin-bottom: 15px;
}
.card-content {
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.db-item {
  background-color: #fff;
  position: relative;
  min-width: 180px;
  cursor: pointer;
  padding: 10px;
  margin: 5px 10px 10px 5px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  .db-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 30px;
    }
  }
  .db-num {
    color: #fff;
    background: #F4628F;
    min-width: 20px;
    padding: 0 10px;
    margin-left: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 10px;
    height: 20px;
  }
  .db-title {
    color: #000;
    margin-bottom: 5px;
  }
  .db-role {
    color: rgba(50,60,71,.5);
  }
}
</style>